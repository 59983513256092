import { ref } from 'vue'
import { usePermissions } from '@/use/permissions'
import { GetCustomModule } from '@/api/customModule'
import { useShop } from '@/use/shop'

export const useEcount = () => {
  const { shopId } = useShop()
  const ecountConfig = ref({})
  const { checkAction } = usePermissions()
  const getEcountConfig = async () => {
    if (!checkAction('admin.shopCustomModule.updateEcountClient')) return
    const [res, err] = await GetCustomModule({
      shopId: shopId.value,
      type: 'ecount',
    })
    if (err) throw err
    ecountConfig.value = res[0]
  }
  return {
    ecountConfig,
    getEcountConfig,
  }
}
