<template>
  <div>
    <section class="card-container" style="padding: 20px">
      <div class="sub-title flex justify-between">
        <div>
          <span class="title-hint">|</span>
          <span>銷售資訊</span>
        </div>
      </div>
      <div class="specs-input-block">
        <div class="flex flex-col" style="margin-right: 10px;width: 195px">
          <span class="font-medium">規格類型</span>
        </div>
        <div class="flex flex-col">
          <span class="font-medium">規格選項<span class="text-xs text-gray-80">( 每種類型最多 6 個項目 )</span></span>
        </div>
      </div>
      <div
        v-for="(block, index) in syncForm.specs"
        :key="`block-${index}`"
        class="specs-input-block"
      >
        <div class="flex flex-col" style="margin-right: 10px">
          <BaseElInput v-model="block.parent" style="width: 195px" />
        </div>
        <div class="flex flex-col">
          <div class="flex" style="align-items: center">
            <BaseElSelect
              v-model="block.child"
              multiple
              filterable
              allow-create
              default-first-option
              clearable
              :multiple-limit="6"
              no-data-text="請輸入文字並 enter 增加規格"
              no-match-text="請輸入文字並 enter 增加規格"
              placeholder="請輸入文字並 enter 增加規格"
            />
            <div
              class="flex justify-center items-center"
              style="cursor: pointer; margin-left: 10px"
              @click="removeBlock(index)"
            >
              <MaterialIcon size="24">delete_filled</MaterialIcon>
            </div>
          </div>
        </div>
      </div>
      <AddButton
        v-if="syncForm.specs.length < 2"
        style="margin-bottom: 30px"
        @click="addBlock"
      />

      <p class="font-medium">一次套用價格</p>
      <section v-if="syncForm.stocks.length" class="quick-input">
        <BaseElForm
          ref="quickInputRef"
          :model="syncForm"
          :rules="formRules"
          class="flex align-items-center"
        >
          <BaseElFormItem style="margin-bottom: 0" prop="quickPrice">
            <BaseElInput
              v-model="syncForm.quickPrice"
              type="number"
              min="0"
              placeholder="請輸入價格"
            />
          </BaseElFormItem>

          <p @click="quickInput">套用</p>
        </BaseElForm>
      </section>
      <section>
        <BaseElForm ref="specRef" :model="syncForm" :rules="specRules">
          <div
            v-for="(spec, index) in syncForm.stocks"
            :key="spec.name"
            class="stock-input-wrapper"
          >
            <div class="flex-col">
              <div v-show="index === 0" class="stock-input-wrapper">
                <div
                  v-for="(spec, index) in specsTitle"
                  :key="index"
                  class="font-medium"
                  style="width: 160px"
                >
                  {{ spec?.parent }}
                </div>
                <div class="font-medium" style="width: 160px">價格</div>
                <div class="font-medium" style="width: 160px">商品編號</div>
                <div class="font-medium" style="width: 160px">庫存</div>
              </div>

              <div class="stock-input-wrapper">
                <div
                  v-for="name in spec.name.split('-')"
                  :key="name"
                  style="width: 160px; line-height: 36px"
                >
                  {{ name.replace('~', '-') }}
                </div>
                <BaseElFormItem prop="price">
                  <BaseElInput
                    v-model="spec.price"
                    style="width: 160px"
                    :min="0"
                    :max="defaultRangeMax"
                    placeholder="價格"
                    type="number"
                    @input="checkInput(spec, spec.price, 'price')"
                  />
                </BaseElFormItem>
                <BaseElFormItem :class="{'is-error': spec.empty || !spec.isExist}" prop="productNo">
                  <div class="flex flex-col items-start">
                    <BaseElInput
                      v-model="spec.productNo"
                      style="width: 160px"
                      placeholder="商品編號"
                      @input="getStock(spec)"
                      @blur="getStock(spec)"
                    />
                    <span v-if="spec.empty" class="danger">請輸入商品編號</span>
                    <span v-if="spec.isExist === false" class="danger">查無此商品</span>
                    <span v-else-if="spec.productName" class="productName">{{ spec.productName }}</span>
                  </div>
                </BaseElFormItem>
                <BaseElFormItem prop="stock">
                  <div class="flex flex-col items-start">
                    <BaseElInput
                      v-model="spec.stock"
                      :min="0"
                      :max="defaultRangeMax"
                      style="width: 160px"
                      placeholder="請先輸入商品編號"
                      type="number"
                      disabled
                      @input="checkInput(spec, spec.stock, 'stock')"
                    />
                    <span v-if="spec.lastSuccessTime" class="productName">更新時間{{ spec.lastSuccessTime }}</span>
                  </div>
                </BaseElFormItem>
                <div
                  class="underline text-primary-100 cursor-pointer"
                  style="line-height: 36px"
                  @click="openRemarkModal($event, spec)"
                >
                  備註
                </div>
              </div>
            </div>
          </div>
        </BaseElForm>
      </section>
    </section>
  </div>
</template>
<script>
import { defineComponent, ref, watch, computed } from 'vue'
import { noEmptyRules, rangeRules, defaultRangeMax } from '@/validation'
import { get, difference, forEach, isEmpty, filter, isNull, find } from 'lodash'
import { useRoute } from 'vue-router/composables'
import formUtils from '@/utils/form'
import debounce from 'lodash.debounce'
import { QueryExternalProductNo } from '@/api/ecount'
import { useVModel } from '@vueuse/core'
import { useShop } from '@/use/shop'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'SpecInfoBlock',
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const route = useRoute()
    const { shopId } = useShop()
    const syncForm = useVModel(props, 'formData', emit)
    const specRef = ref(null)
    const specRules = {
      stocks: {
        productNo: [noEmptyRules('請輸入商品編號', true)],
      },
    }
    const quickInputRef = ref(null)
    const specsTitle = computed(() => {
      return filter(syncForm.value.specs, (item) => {
        return !isEmpty(item.parent) && !isEmpty(item.child)
      })
    })
    const formRules = computed(() => {
      return {
        quickPrice: [noEmptyRules('請輸入資料', true), rangeRules()],
      }
    })
    const mode = computed(() => get(route.meta, 'type'))
    const specsArrary = computed(() => {
      let outerTemp = []
      syncForm.value.specs.forEach((spec) => {
        const temp = []

        if (!spec.child.length) return
        if (outerTemp.length) {
          outerTemp.forEach((parent) => {
            spec.child.forEach((child) => {
              const indexs = JSON.parse(
                JSON.stringify(parent.EcommerceProductChildSpecs),
              )
              indexs.push({ index: findSpecIndex(child) })

              temp.push({
                name: `${parent.name.replace('-', '~')}-${child.replace('-', '~')}`,
                EcommerceProductChildSpecs: indexs,
                stock: '',
                price: '',
                productNo: undefined,
                order: '',
              })
            })
          })
        } else {
          spec.child.forEach((item) => {
            temp.push({
              name: item.replace('-', '~'),
              EcommerceProductChildSpecs: [{ index: findSpecIndex(item) }],
              stock: '',
              price: '',
              productNo: undefined,
              order: '',
            })
          })
        }
        outerTemp = temp
      })
      return outerTemp
    })
    const childSpecsFlat = computed(() => {
      const list = []
      syncForm.value.specs.forEach((spec, index) => {
        spec.child.forEach((child, childIndex) => {
          const data = {
            name: child.replace('-', '~'),
            disable: false,
            order: childIndex + 1,
            EcommerceProductParentSpecIndex: index,
          }
          if (mode.value === 'modify') {
            if (spec.childsID) {
              const childID = spec.childsID.find((c) => c.name === child)
              if (childID) data.id = childID.id
            }
          }
          list.push(data)
        })
      })
      return list
    })
    const findSpecIndex = (name) => {
      return childSpecsFlat.value.indexOf(
        childSpecsFlat.value.find((_) => _.name === name.replace('-', '~')),
      )
    }
    const syncStocks = () => {
      const frame = JSON.parse(JSON.stringify(specsArrary.value))
      frame.forEach((item) => {
        const res = syncForm.value.stocks.find(
          (stock) =>
            !difference(stock.name.split('-'), item.name.split('-')).length,
        )
        if (res) {
          const exist = JSON.parse(JSON.stringify(res))
          item.price = exist.price
          item.stock = exist.stock
          item.adminNote = exist.adminNote
          item.productNo = exist.productNo || undefined
          item.id = exist.id
          item.lastSuccessTime = exist.lastSuccessTime || null
          item.productName = exist.productName || ''
          item.isExist = exist.isExist
        }
      })

      syncForm.value.stocks = frame
    }

    watch(specsArrary, () => { syncStocks() })

    const openRemarkModal = (e, item) => {
      console.log('openRemarkModal', e)
      emit('openRemarkModal', item)
    }

    const addBlock = () => {
      syncForm.value.specs.push({
        parent: '',
        child: [],
      })
    }
    const removeBlock = (index) => {
      syncForm.value.specs.splice(index, 1)
    }
    const quickInput = async () => {
      if (!(await formUtils.checkForm(quickInputRef.value))) return
      const price = syncForm.value.quickPrice
      forEach(syncForm.value.stocks, (item) => {
        item.price = price
      })
      syncForm.value.quickPrice = null
    }
    const checkInput = (target, value, type) => {
      target[type] = Number(value)
      if (value < 0) {
        target[type] = 0
      }
      if (value > defaultRangeMax) {
        target[type] = defaultRangeMax
      }
    }
    const getStock = debounce(async (item) => {
      item.productName = ''
      if (!item.productNo) {
        item.empty = true
        item.isExist = true
        return
      }
      item.empty = false
      const payload = {
        productNos: [item.productNo],
      }
      const res = await queryExternalProductNo(payload.productNos)
      if (res) {
        const products = get(res, 'products')
        forEach(syncForm.value.stocks, (stock) => {
          const product = find(products, (product) => product.productNo === stock.productNo);
          if (product) {
            stock.productName = product.name;
            stock.stock = product.quantity;
            stock.isExist = product.isExist;
            stock.lastSuccessTime = res.lastSuccessTime ? formatDate(res.lastSuccessTime) : null;
            console.log('stock', stock.productName);
          }
        });
      }
    }, 300, { leading: false, trailing: true })
    const queryExternalProductNo = async (productNos) => {
      const [res, err] = await QueryExternalProductNo({ shopId: shopId.value, productNos })
      if (err) window.$message.error(err)
      if (res) return res
    }
    return {
      syncForm,
      formRules,
      specRules,
      specRef,
      addBlock,
      removeBlock,
      quickInput,
      quickInputRef,
      defaultRangeMax,
      checkInput,
      specsTitle,
      getStock,
      isNull,
      openRemarkModal,
    }
  },
})

</script>
<style lang="scss" scoped>
$primary-1: var(--primary-100);

.quick-input {
  display: flex;
  align-items: center;
  padding-top: 8px;
  .el-input {
    margin-right: 20px;
  }
  p {
    text-decoration: underline;
    color: $primary-1;
    cursor: pointer;
  }
}
.specs-input-block {
  display: flex;
  margin-bottom: 16px;
}
.sub-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;

  .title-hint {
    color: $primary-1;
    margin-right: 5px;
  }
}
.stock-input-wrapper {
  display: grid;
  grid-auto-flow: column;
  align-items: start;
  gap: 20px;
  width: fit-content;
  word-break: break-all;
  margin-bottom: 12px;
}
.danger {
  @apply text-sm text-danger leading-normal
}
.productName {
  @apply text-sm text-gray-60 leading-normal
}
</style>
