<template>
  <div class="product-create">
    <ImageCropper
      v-if="uploadDialog"
      :image="image"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
    <ImageCropper
      v-if="mainUploadDialog"
      :image="image"
      @close="mainUploadDialog = false"
      @uploaded="getImageMain"
    />
    <ImageCropper
      v-if="otherUploadDialog"
      :image="image"
      :ratio="[400, 400]"
      @close="otherUploadDialog = false"
      @uploaded="getImageOther"
    />

    <header class="view-title">
      商品項目設定
      <BaseElButton
        v-if="showSyncBtn"
        v-loading="loadingSyncBtn"
        class="btn page-title-btn"
        :plain="showSyncBtn? true : false"
        :type="showSyncBtn? '' : 'primary'"
        @click="syncOneProduct"
      >
        同步Rezio商品
      </BaseElButton>
    </header>
    <section>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ name: 'EcommerceProductSetting' }">
          商品項目設定
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'EcommerceProductSetting' }">
          新增商品項目
        </el-breadcrumb-item>
      </el-breadcrumb>
    </section>
    <section class="card-container" style="padding: 20px">
      <div class="sub-title flex-between">
        <div>
          <span class="title-hint">|</span>
          <span>基本資訊</span>
        </div>
      </div>

      <BaseElForm
        ref="form"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem
          label="首圖"
          prop="mainImg"
          class="form-relative-label main"
        >
          <div class="flex gap-10">
            <div v-if="formData.mainImg !== ''" class="img-wrapper">
              <i
                class="el-icon-circle-close remove-btn"
                @click="formData.mainImg = ''"
              />
              <img :src="imgUrl(formData.mainImg)" alt="">
            </div>
            <UploadButton
              v-if="formData.mainImg === ''"
              style="width: 90px; height: 90px"
              :img="null"
              @change="mainImageChange"
            />
          </div>
        </BaseElFormItem>

        <BaseElFormItem
          label="圖片"
          prop="Images"
          class="form-relative-label imgs"
        >
          <div class="flex gap-10">
            <div
              v-for="(img, index) in formData.Images"
              :key="img.id"
              class="img-wrapper"
            >
              <i
                class="el-icon-circle-close remove-btn"
                @click="removeImg('images', index)"
              />
              <img :src="imgUrl(img)" alt="">
            </div>
            <UploadButton
              v-if="formData.Images.length < 4"
              style="width: 90px; height: 90px"
              :img="null"
              @change="imageChange"
            />
          </div>
        </BaseElFormItem>

        <BaseElFormItem label="公開顯示" prop="isPublic">
          <BaseElSwitch
            v-model="formData.isPublic"
            active-color="#2BB8C1"
            inactive-color="#AEAEAE"
            active-text="是"
            inactive-text="否"
          />
        </BaseElFormItem>

        <BaseElFormItem label="排序" prop="order">
          <BaseElInput v-model="formData.order" placeholder="文字" />
        </BaseElFormItem>
        <BaseElFormItem label="名稱" prop="name">
          <BaseElInput
            v-model="formData.name"
            placeholder="文字"
            maxlength="50"
            show-word-limit
          />
        </BaseElFormItem>
        <!-- <BaseElFormItem label="描述" prop="description">
          <BaseElInput
            type="textarea"
            placeholder="文字"
            style="width: 400px"
            maxlength="1000"
            show-word-limit
            v-model="formData.description"
          />
        </BaseElFormItem> -->
        <BaseElFormItem label="商品類別" prop="ecommerceCategory">
          <ProductCategorySelect :model.sync="formData.ecommerceCategory" />
        </BaseElFormItem>
        <BaseElFormItem v-if="useRebate" label="回饋金可折抵上限（%）" prop="maxRebateRatio">
          <BaseElInput v-model="formData.maxRebateRatio" placeholder="請輸入百分比">
            <p slot="suffix">%</p>
          </BaseElInput>
        </BaseElFormItem>
        <BaseElFormItem label="活動" prop="ecommerceActivity">
          <ActivitySelect :model.sync="formData.ecommerceActivity" />
        </BaseElFormItem>

        <BaseElFormItem label="商品簡述" prop="shortDescription">
          <BaseElInput v-model="formData.shortDescription" />
        </BaseElFormItem>

        <BaseElFormItem label="注意事項" prop="caution">
          <BaseElInput v-model="formData.caution" />
        </BaseElFormItem>

        <BaseElFormItem label="商品資訊" prop="description" label-position="top">
          <div class="card-container">
            <quillEditor
              v-model="formData.description"
              :options="editorOption"
            />
          </div>
        </BaseElFormItem>
        <BaseElFormItem
          label="更多資訊"
          class="form-relative-label others"
          label-position="top"
        >
          <div class="flex gap-10 flex-wrap">
            <div
              v-for="(img, index) in formData.otherImages"
              :key="img.id"
              class="img-wrapper"
            >
              <i
                class="el-icon-circle-close remove-btn"
                @click="removeImg('others', index)"
              />
              <img :src="imgUrl(img)" alt="">
            </div>
            <UploadButton
              v-if="formData.otherImages.length < 10"
              style="width: 90px; height: 90px"
              :img="null"
              @change="otherImageChange"
            />
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </section>
    <!-- Ecount ERP-->
    <SpecInfoBlock
      v-if="showEcount"
      :formData.sync="formData"
      @openRemarkModal="openRemarkModal"
    />
    <section v-else class="card-container" style="padding: 20px">
      <div class="sub-title flex justify-between">
        <div>
          <span class="title-hint">|</span>
          <span>銷售資訊</span>
        </div>
      </div>

      <div class="notice">
        <div style="width: 450px; padding: 16px 0">
          商品規格：為商品新增規格，例如：尺寸、顏色 <br>
          規格設定數量限制2個，選項設定數量限制6個
        </div>

        <!-- <div class="reset-btn" @click="reset">刪除</div> -->
      </div>

      <div
        v-for="(block, index) in formData.specs"
        :key="`block-${index}`"
        class="specs-input-block"
      >
        <div class="flex flex-col" style="margin-right: 10px">
          <span>規格類型{{ index + 1 }}</span>
          <BaseElInput v-model="block.parent" style="width: 195px" />
        </div>
        <div class="flex flex-col">
          <span>選項</span>
          <div class="flex" style="align-items: center">
            <BaseElSelect
              v-model="block.child"
              multiple
              filterable
              allow-create
              default-first-option
              clearable
              :multiple-limit="6"
              no-data-text="請輸入選項"
              no-match-text="請輸入選項"
              placeholder="請輸入選項"
            />
            <div
              class="flex justify-center items-center"
              style="cursor: pointer; margin-left: 10px"
              @click="removeBlock(index)"
            >
              <MaterialIcon size="24">delete_filled</MaterialIcon>
            </div>
            <!-- <i
              v-show="formData.specs.length > 1"
              class="el-icon-circle-close"
              style="width: 16.67px; cursor: pointer; margin-left: 10px"
              @click="removeBlock(index)"
            /> -->
          </div>
        </div>
      </div>
      <AddButton
        v-if="formData.specs.length < 2"
        style="margin-bottom: 30px"
        @click="addBlock"
      />
      <!-- <BaseElButton
        v-if="formData.specs.length < 2"
        plain
        icon="el-icon-plus"
        style="
          font-size: 14px;
          background: transparent;
          color: #5e5e5e;
          border: solid 2px #dadada;
          border-radius: 5px;
          padding: 6px 12px;
          box-shadow: none;
        "
        @click="addBlock"
      >
        新增
      </BaseElButton> -->

      <p>商品價格與庫存</p>
      <section v-if="formData.stocks.length" class="quick-input">
        <BaseElForm
          ref="quickInput"
          :model="formData"
          :rules="formRules"
          class="flex align-items-center"
        >
          <BaseElFormItem style="margin-bottom: 0" prop="quickPrice">
            <BaseElInput
              v-model="formData.quickPrice"
              type="number"
              min="0"
              placeholder="價格"
              style="width: 195px"
            />
          </BaseElFormItem>

          <BaseElFormItem style="margin-bottom: 0" prop="quickStock">
            <BaseElInput
              v-model="formData.quickStock"
              min="0"
              type="number"
              placeholder="庫存"
              style="width: 195px"
            />
          </BaseElFormItem>
          <p @click="quickInput">套用</p>
        </BaseElForm>
      </section>
      <section>
        <div
          v-for="(spec, index) in formData.stocks"
          :key="spec.name"
          class="stock-input-wrapper"
        >
          <div class="flex-col">
            <div v-show="index === 0" class="stock-input-wrapper">
              <div
                v-for="(name, index) in spec.name.split('-')"
                :key="name"
                style="width: 110px"
              >
                規格{{ index + 1 }}
              </div>
              <div style="width: 110px">價格</div>
              <div style="width: 110px">庫存</div>
              <div style="width: 110px">商品編號</div>
            </div>

            <div class="stock-input-wrapper">
              <div
                v-for="name in spec.name.split('-')"
                :key="name"
                style="width: 110px"
              >
                {{ name }}
              </div>
              <BaseElInput
                v-model="spec.price"
                style="width: 110px"
                :min="0"
                :max="defaultRangeMax"
                placeholder="價格"
                type="number"
                @input="checkInput(spec, spec.price, 'price')"
              />
              <BaseElInput
                v-model="spec.stock"
                :min="0"
                :max="defaultRangeMax"
                style="width: 110px"
                placeholder="庫存"
                type="number"
                @input="checkInput(spec, spec.stock, 'stock')"
              />
              <BaseElInput
                v-model="spec.productNo"
                style="width: 110px"
                placeholder="商品編號"
              />
              <BaseElButton
                class="underline"
                type="text"
                @click=";(selectSpec = spec), (showShopCommentDialog = true)"
              >
                備註
              </BaseElButton>
            </div>
          </div>
        </div>
      </section>
    </section>

    <section class="card-container" style="padding: 20px">
      <div class="sub-title flex-between">
        <div>
          <span class="title-hint">|</span>
          <span>相關商品</span>
        </div>
      </div>
      <p>選擇四件相關商品，推薦給消費者。</p>
      <BaseElForm>
        <BaseElFormItem label="商品">
          <ProductSelect
            :model.sync="formData.relatesProducts"
            multiple
            :maxSelect="4"
          />
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <ShopCommentDialog
      v-if="showShopCommentDialog"
      :data="selectSpec"
      @close="showShopCommentDialog = false"
      @update="updateAdminNote(selectSpec, $event)"
    />
    <PageFixedFooter
      :confirmBtn="mode === 'modify' ? '儲存' : '新增'"
      @cancel="$router.go(-1)"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import ProductSelect from '@/components/Search/ecommerce/ProductSelect.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import ActivitySelect from '@/components/Search/ecommerce/ActivitySelect.vue'
import ProductCategorySelect from '@/components/Search/ecommerce/ProductCategorySelect.vue'
import ShopCommentDialog from './components/ShopCommentDialog.vue'
import SpecInfoBlock from './components/SpecInfoBlock.vue'
import { checkUserFeature } from '@/store/modules/permission'
import { extractList, imgSrc } from '@/utils/helper'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  CreateProduct,
  FindProduct,
  UpdateProduct,
} from '@/api/ecommerce/product'
import {
  SyncAllRezioProduct,
  SyncOneRezioProduct,
} from '@/api/ecommerce/rezioProduct'
import { QueryExternalProductNo } from '@/api/ecount'
import { mapGetters } from 'vuex'
import {
  noEmptyRules,
  isDigitRules,
  rangeRules,
  defaultRangeMax,
} from '@/validation'
import formUtils from '@/utils/form'
import { forEach, difference, map, get, find } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { checkAction } from '@/utils/permissions'
import { usePermissions } from '@/use/permissions'
import { useEcount } from '@/use/useEcount'
import { useShop } from '@/use/shop'
import { formatDate } from '@/utils/date'

export default {
  name: 'ProductCreate',
  components: {
    UploadButton,
    ImageCropper,
    ActivitySelect,
    ProductCategorySelect,
    quillEditor,
    ProductSelect,
    ShopCommentDialog,
    SpecInfoBlock,
  },
  setup() {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { ecountConfig, getEcountConfig } = useEcount()
    const showShopCommentDialog = ref(false)
    const selectSpec = ref(null)
    const showEcount = computed(() => checkAction('admin.shopCustomModule.updateEcountClient') && get(ecountConfig.value, 'enabled'))
    const openRemarkModal = (spec) => {
      selectSpec.value = spec
      showShopCommentDialog.value = true
    }
    onMounted(async() => {
      await getEcountConfig()
    })
    return {
      showEcount,
      shopId,
      openRemarkModal,
      showShopCommentDialog,
      selectSpec,
    }
  },
  data: () => ({
    defaultRangeMax,
    value: [],
    showInput: false,
    uploadDialog: false,
    mainUploadDialog: false,
    otherUploadDialog: false,
    // showShopCommentDialog: false,
    image: null,
    // selectSpec: null,
    formData: {
      relatesProducts: [],
      ecommerceActivity: '',
      ecommerceCategory: '',
      mainImg: '',
      order: 100,
      name: '',
      description: '',
      shortDescription: '',
      caution: '',
      maxRebateRatio: null,
      Images: [],
      otherImages: [],
      isPublic: true,
      specs: [
        {
          parent: '',
          child: [],
        },
      ],
      stocks: [],
      quickStock: null,
      quickPrice: null,
      productExternalParam: null,
    },

    formRules: {
      order: [noEmptyRules(), isDigitRules(), rangeRules()],
      mainImg: [noEmptyRules()],
      name: [noEmptyRules()],
      description: [noEmptyRules()],
      ecommerceCategory: [noEmptyRules()],
      maxRebateRatio: [noEmptyRules(), isDigitRules(), rangeRules(0, 100)],
      quickStock: [noEmptyRules('請輸入資料', true), rangeRules()],
      quickPrice: [noEmptyRules('請輸入資料', true), rangeRules()],
    },

    editorOption: {
      modules: {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    },

    loadingSyncBtn: false,
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userFeatures',
      'userPlanFeature',
    ]),
    mode () {
      return this.$route.meta.type
    },
    imageIdList () {
      return extractList('id', this.formData.Images)
    },
    otherImageIdList () {
      return extractList('id', this.formData.otherImages)
    },
    pageType () {
      return this.$route.meta.type
    },
    parentSpecsFlat () {
      const list = []
      this.formData.specs.forEach((item, index) => {
        const data = {
          name: item.parent.replace('-', '~'),
          order: index + 1,
        }
        if (this.mode === 'modify') {
          if (item.parentId) data.id = item.parentId
        }
        list.push(data)
      })

      return list
    },
    childSpecsFlat () {
      const list = []
      this.formData.specs.forEach((spec, index) => {
        spec.child.forEach((child, childIndex) => {
          const data = {
            name: child.replace('-', '~'),
            disable: false,
            order: childIndex + 1,
            EcommerceProductParentSpecIndex: index,
          }
          if (this.mode === 'modify') {
            if (spec.childsID) {
              const childID = spec.childsID.find((c) => c.name === child)
              if (childID) data.id = childID.id
            }
          }
          list.push(data)
        })
      })

      return list
    },

    specsArrary () {
      let outerTemp = []
      this.formData.specs.forEach((spec) => {
        const temp = []

        if (!spec.child.length) return
        if (outerTemp.length) {
          outerTemp.forEach((parent) => {
            spec.child.forEach((child) => {
              const indexs = JSON.parse(
                JSON.stringify(parent.EcommerceProductChildSpecs),
              )
              indexs.push({ index: this.findSpecIndex(child) })

              temp.push({
                name: `${parent.name.replace('-', '~')}-${child.replace('-', '~')}`,
                EcommerceProductChildSpecs: indexs,
                stock: '',
                price: '',
                productNo: undefined,
                order: '',
              })
            })
          })
        } else {
          spec.child.forEach((item) => {
            temp.push({
              name: item.replace('-', '~'),
              EcommerceProductChildSpecs: [{ index: this.findSpecIndex(item) }],
              stock: '',
              price: '',
              productNo: undefined,
              order: '',
            })
          })
        }
        outerTemp = temp
      })
      return outerTemp
    },

    // 用於創建商品時的規格列表
    stockList () {
      const stocks = JSON.parse(JSON.stringify(this.formData.stocks))
      stocks.forEach((item, index) => {
        // 創建時不需組合名稱故刪除
        // name 是一開始用來比對同步資料用的
        delete item.name
        delete item.order
        delete item.isExist
        delete item.productName
        delete item.empty
        delete item.lastSuccessTime
        if (item.productNo === '') item.productNo = undefined
      })
      return stocks
    },

    useRebate () {
      return this.checkAction('adminView.ecommerceProduct.useCashbackRate')
    },

    showSyncBtn () {
      return (this.$route.meta.type === 'modify') && checkAction('admin.rezio.sync')
    },
  },

  watch: {
    specsArrary () {
      this.syncStocks()
    },
  },

  async mounted () {
    if (this.pageType === 'modify') await this.findProduct()
  },
  methods: {
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
    updateAdminNote (spec, data) {
      if (!spec) return
      console.log(data)
      spec.adminNote = data
      console.log(spec)
      console.log(spec.adminNote)
    },
    checkInput (target, value, type) {
      target[type] = Number(value)
      if (value < 0) {
        target[type] = 0
      }
      if (value > this.defaultRangeMax) {
        target[type] = this.defaultRangeMax
      }
    },
    async quickInput () {
      if (!(await formUtils.checkForm(this.$refs.quickInput))) return
      const stock = this.formData.quickStock
      const price = this.formData.quickPrice
      forEach(this.formData.stocks, (item) => {
        item.stock = stock
        item.price = price
      })
      this.formData.quickStock = null
      this.formData.quickPrice = null
    },
    async handleConfirm () {
      if (this.pageType === 'create') await this.createProduct()
      if (this.pageType === 'modify') await this.updateProduct()
    },
    async createProduct () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      if (!this.checkEmpty()) return
      const form = this.formData
      const [res, error] = await CreateProduct({
        shopId: this.shop,
        mainImage: form.mainImg.id,
        images: this.imageIdList,
        descriptionImages: this.otherImageIdList,
        name: form.name,
        order: form.order,
        description: form.description,
        shortDescription: form.shortDescription,
        caution: form.caution,
        showActivityPrice: true,
        isPublic: form.isPublic,
        useCashbackRate: this.useRebate ? form.maxRebateRatio / 100 : undefined,
        ecommerceActivity: form.ecommerceActivity.id || null,
        ecommerceCategory: form.ecommerceCategory.id,
        ecommerceProductParentSpecs: this.parentSpecsFlat,
        ecommerceProductChildSpecs: this.childSpecsFlat,
        ecommerceProductStocks: this.stockList,
        ecommerceProductRelateds: form.relatesProducts.length
          ? map(form.relatesProducts, 'id')
          : undefined,
      })

      if (error) return this.$message.error(error)
      if (res) {
        this.$message.success('新增商品成功！')
        console.log(res)
        this.$router.push({ name: 'EcommerceProductSetting' })
      }
    },
    addBlock () {
      this.formData.specs.push({
        parent: '',
        child: [],
      })
    },

    syncStocks () {
      const frame = JSON.parse(JSON.stringify(this.specsArrary))
      frame.forEach((item) => {
        const res = this.formData.stocks.find(
          (stock) =>
            !difference(stock.name.split('-'), item.name.split('-')).length,
        )
        if (res) {
          const exist = JSON.parse(JSON.stringify(res))
          item.price = exist.price
          item.stock = exist.stock
          item.adminNote = exist.adminNote
          item.productNo = exist.productNo || undefined
          item.id = exist.id
          item.productName = exist.productName
          item.isExist = exist.isExist
          item.lastSuccessTime = exist.lastSuccessTime
        }
      })

      this.formData.stocks = frame
    },

    removeBlock (index) {
      this.formData.specs.splice(index, 1)
    },

    checkEmpty () {
      const emptyParentName = this.parentSpecsFlat.find(
        (item) => item.name === '',
      )

      const emptyStockProp = this.stockList.find((item) => {
        if (item.stock === '') return true
        if (item.price === '') return true
        // if (item.productNo === '') return true
      })
      if (this.showEcount) {
        const emptyProductNo = this.stockList.find((item) => item.productNo === undefined)
        const productNotFound = this.stockList.find((item) => item.stock === null)
        if (emptyProductNo || productNotFound) {
          this.$message.warning('請完整填寫商品編號')
          return false
        }
      }
      if (!this.childSpecsFlat.length) {
        this.$message.warning('請完整填寫規格選項')
        return false
      } else if (emptyParentName) {
        this.$message.warning('請完整填寫規格名稱')
        return false
      } else if (emptyStockProp) {
        this.$message.warning('請完整填寫庫存、價格')
        return false
      }
      return true
    },

    async findProduct () {
      const [res, error] = await FindProduct({
        shopId: this.shop,
        id: this.$route.params.id,
      })
      if (error) return this.$message.error(error)
      // sync
      this.syncData(res)
    },

    async updateProduct () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      if (!this.checkEmpty()) return
      const form = this.formData
      const [res, error] = await UpdateProduct({
        shopId: this.shop,
        id: this.$route.params.id,
        mainImage: form.mainImg.id,
        images: this.imageIdList,
        descriptionImages: this.otherImageIdList,
        name: form.name,
        order: form.order,
        description: form.description,
        shortDescription: form.shortDescription,
        caution: form.caution,
        // showActivityPrice: form.showActivityPrice,
        isPublic: form.isPublic,
        useCashbackRate: this.useRebate ? form.maxRebateRatio / 100 : undefined,
        ecommerceActivity: form.ecommerceActivity.id || null,
        ecommerceCategory: form.ecommerceCategory.id,
        ecommerceProductParentSpecs: this.parentSpecsFlat,
        ecommerceProductChildSpecs: this.childSpecsFlat,
        ecommerceProductStocks: this.stockList,
        ecommerceProductRelateds: form.relatesProducts.length
          ? map(form.relatesProducts, 'id')
          : undefined,
      })
      if (error) return this.$message.error(error)
      this.$message.success('更新商品成功！')
      this.$router.push({ name: 'EcommerceProductSetting' })
    },

    async syncData (res) {
      this.formData.mainImg = res.MainImage
      this.formData.Images = res.Images
      this.formData.otherImages = res.DescriptionImages
      this.formData.order = res.order
      this.formData.name = res.name
      this.formData.description = res.description
      this.formData.shortDescription = res.shortDescription
      this.formData.caution = res.caution
      this.formData.isPublic = res.isPublic
      this.formData.maxRebateRatio = res.useCashbackRate * 100
      // this.formData.showActivityPrice = res.showActivityPrice
      this.formData.ecommerceCategory = { id: res.EcommerceCategoryId }
      this.formData.ecommerceActivity = { id: res.EcommerceActivityId }
      this.formData.relatesProducts = res.EcommerceProductRelateds
      this.formData.productExternalParam = res.EcommerceProductExternalParam

      this.formData.specs = []

      // 找出每個父規格下擁有的子規格
      res.EcommerceProductParentSpecs.forEach((parent) => {
        const childList = []
        const childsID = []
        res.EcommerceProductChildSpecs.sort((a, b) => a.order - b.order)
        res.EcommerceProductChildSpecs.forEach((child) => {
          if (child.EcommerceProductParentSpecId === parent.id) {
            childList.push(child.name)
            childsID.push({ name: child.name, id: child.id })
          }
        })

        this.formData.specs.push({
          parent: parent.name.replace('-', '~'),
          parentId: parent.id,
          order: parent.order,
          child: childList,
          childsID,
        })

        this.formData.specs.sort((a, b) => a.order - b.order)
      })

      // 重新組合所有庫存組合
      const list = []
      // res.EcommerceProductStocks.forEach((item) => {
      let erpProducts = []
      if (this.showEcount) {
        const productNos = res.EcommerceProductStocks.map((item) => item.productNo)
        erpProducts = await this.queryExternalProductNo(this.shop, productNos)
      }
      for (const item of res.EcommerceProductStocks) {
        const data = {
          name: '',
          id: item.id,
          EcommerceProductChildSpecs: [],
          price: item.price,
          productNo: item.productNo || undefined,
          stock: item.stock,
          adminNote: item.adminNote,
          // productName: erpProduct ? erpProduct.name : '',
          // isExist: !!erpProduct,
        }
        const erpProduct = find(erpProducts.products, { productNo: item.productNo })
        if (erpProduct) {
          data.productName = erpProduct.name
          data.lastSuccessTime = erpProducts.lastSuccessTime ? formatDate(erpProducts.lastSuccessTime) : null
          data.isExist = true
        } else {
          data.productName = ''
          data.isExist = false
        }
        // 組合規格名稱 ex. 大-藍色
        const nameList = []
        item.EcommerceProductChildSpecs.forEach((spec) => {
          nameList.push(spec.name.replace('-', '~'))
          data.EcommerceProductChildSpecs.push({
            index: this.findSpecIndex(spec.name), // 找出子規格的 index
          })
        })
        data.name = nameList.join('-')

        list.push(data)
      }

      this.$nextTick(() => {
        this.formData.stocks = list
        this.syncStocks()
      })
    },
    async queryExternalProductNo (shopId, productNos) {
      const [res, err] = await QueryExternalProductNo({ shopId, productNos})
      if (err) window.$message.error(err)
      if (res) {
        // const data = res[0]
        // return data
        return res
      }
    },
    findSpecIndex (name) {
      return this.childSpecsFlat.indexOf(
        this.childSpecsFlat.find((_) => _.name === name.replace('-', '~')),
      )
    },
    imageChange (img) {
      this.image = img
      this.uploadDialog = true
    },
    mainImageChange (img) {
      this.image = img
      this.mainUploadDialog = true
    },
    otherImageChange (img) {
      this.image = img
      this.otherUploadDialog = true
    },

    getImage (img) {
      this.formData.Images.push(img)
      this.image = img
      this.uploadDialog = false
    },
    getImageMain (img) {
      this.formData.mainImg = img
      this.image = img
      this.mainUploadDialog = false
    },
    getImageOther (img) {
      this.formData.otherImages.push(img)
      this.image = img
      this.otherUploadDialog = false
    },
    imgUrl (img) {
      return imgSrc(300, img)
    },

    removeImg (stack, index) {
      if (stack === 'images') this.formData.Images.splice(index, 1)
      if (stack === 'others') this.formData.otherImages.splice(index, 1)
    },

    async syncOneProduct () {
      this.loadingSyncBtn = true
      const [_, error] = await SyncOneRezioProduct({
        shopId: this.shop,
        id: this.formData.productExternalParam.id,
      })
      this.loadingSyncBtn = false
      if (error) return this.$message.error(error)
      this.$message.success('同步成功')
    },

    reset () {
      this.formData.specs = [
        {
          parent: '',
          child: [],
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.specs-input-block .el-input {
  height: auto !important;
}
</style>

<style lang="scss" scoped>
$primary-1: var(--primary-100);

::v-deep .el-select{
  @apply h-auto;
}

.quick-input {
  display: flex;
  align-items: center;
  padding-top: 8px;
  .el-input {
    margin-right: 20px;
  }
  p {
    text-decoration: underline;
    color: $primary-1;
    cursor: pointer;
  }
}

.specs-input-block {
  display: flex;
  margin-bottom: 16px;
}
.sub-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;

  .title-hint {
    color: $primary-1;
    margin-right: 5px;
  }
}

.img-wrapper {
  position: relative;
  width: 90px;
  border-radius: 4px;

  img {
    border-radius: 4px;
  }
}

.stock-input-wrapper {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 20px;
  width: fit-content;
  word-break: break-all;
  margin-bottom: 12px;
}

.remove-btn {
  border-radius: 4px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
}

.message-tab-footer {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  gap: 30px;
  box-shadow: 0px -4px 8px rgba(47, 32, 27, 0.04);
  padding: 15px 60px !important;
  background: white;
  z-index: 2;
}

.notice {
  display: flex;
  align-items: center;
  .reset-btn {
    color: $primary-1;
    text-decoration: underline;
    cursor: pointer;
  }
}
.form-relative-label {
  @apply relative;
}
.form-relative-label.others::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[82px] top-[2px] text-sm text-gray-80;
}
.form-relative-label.main::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[50px] top-[2px] text-sm text-gray-80;
}
.form-relative-label.imgs::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[34px] top-[2px] text-sm text-gray-80;
}
.page-title-btn {
  margin: 0 20px;
}
</style>
